import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { FaqItemStoryblok } from '@/types/storyblok-component-types'
import AccordeonItem from './AccordeonItem'

export default function FAQItem({
  blok,
  ...props
}: {
  blok: FaqItemStoryblok
}) {
  return (
    <AccordeonItem
      {...props}
      title={blok.question}
      key={blok._uid}
      id={blok._uid}
      {...storyblokEditable(blok)}
    >
      <RichTextParagraphStyles>
        <StoryblokRichText document={blok.answer} />
      </RichTextParagraphStyles>
    </AccordeonItem>
  )
}
