import CartButton from '../Header/CartButton/CartButton'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import { useRouter } from 'next/router'
import { HeaderLogo } from '@/app/common/components/Header/Logo/Logo'
import { SearchButton } from '@/app/common/components/Header/SeachButton/SearchButton'
import { cn } from '@/utils/cn'
import AccountButton from './AccountButton/AccountButton'
import MenuButton from './MenuButton/MenuButton'
import { Center, GridItem } from './components'
import styles from './header.module.css'

type Props = {
  menuOpen: boolean
  toggleMenu: () => void
  itemsCount: number
  isLoggedIn: boolean
  onLoginClick: () => void
  onLogoutClick: () => void
}

export const TabletPrimaryHeader = ({
  menuOpen,
  toggleMenu,
  itemsCount,
  isLoggedIn,
  onLoginClick,
  onLogoutClick,
}: Props) => {
  return (
    <div
      className={cn(
        'hidden md:max-lg:grid bg-white',
        itemsCount > 0
          ? styles.tabletPrimaryHeaderWithCart
          : styles.tabletPrimaryHeaderWithoutCart,
      )}
    >
      <PrimaryHeader
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
        itemsCount={itemsCount}
        isLoggedIn={isLoggedIn}
        onLoginClick={onLoginClick}
        onLogoutClick={onLogoutClick}
      />
    </div>
  )
}

export const PrimaryHeaderCheckout = ({
  menuOpen,
  toggleMenu,
  itemsCount,
  isLoggedIn,
  onLoginClick,
  onLogoutClick,
}: Exclude<Props, 'isCheckout'>) => {
  return (
    <div
      className={cn(
        'hidden md:grid bg-white',
        styles.tabletPrimaryHeaderCheckout,
      )}
    >
      <PrimaryHeader
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
        itemsCount={itemsCount}
        isLoggedIn={isLoggedIn}
        onLoginClick={onLoginClick}
        onLogoutClick={onLogoutClick}
      />
    </div>
  )
}

const PrimaryHeader = ({
  menuOpen,
  toggleMenu,
  itemsCount,
  isLoggedIn,
  onLoginClick,
  onLogoutClick,
}: Props) => {
  const router = useRouter()
  const isCheckout = router.pathname.includes('/checkout')
  const isFinalizeAccountSetup = router.pathname.includes(
    '/finalize-account-setup',
  )

  return (
    <>
      <GridItem area="menu">
        <Center center>
          <MenuButton menuOpen={menuOpen} toggleMenu={toggleMenu} />
        </Center>
      </GridItem>

      <GridItem area="logo">
        <Center>
          <HeaderLogo />
        </Center>
      </GridItem>

      {!isCheckout && (
        <GridItem area="search">
          <Center>
            <SearchButton />
          </Center>
        </GridItem>
      )}
      {!isCheckout && !isFinalizeAccountSetup && itemsCount > 0 && (
        <GridItem zIndex={1} area="cart">
          <Center center>
            <CartButton itemsCount={itemsCount} />
          </Center>
        </GridItem>
      )}
      {!isCheckout && !isFinalizeAccountSetup && (
        <AccountButton
          isLoggedIn={isLoggedIn}
          onLoginClick={onLoginClick}
          onLogoutClick={onLogoutClick}
        />
      )}
      {!isCheckout && (
        <GridItem zIndex={1} area="language">
          <LanguageSelector />
        </GridItem>
      )}
    </>
  )
}
