import CashyLogo from '../../../../icons/cashy-logo.svg'
import { useLoginCustomer } from '../../auth.service'
import AuthDialog from '../AuthDialog/AuthDialog'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import Register from '../Register/Register'
import { Form, Formik } from 'formik'
import { NextPage } from 'next'
import useTranslation from 'next-translate/useTranslation'
import { useContext } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { SmartTextInput } from '@/app/common/components/Form'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { Button } from '@/components/Button'
import * as tracking from '@/helpers/tracking'
import { ErrorBox } from '@/style/components/ErrorBox/ErrorBox'
import Text from '@/style/components/LegacyText'
import { SectionInner } from '@/style/components/Section'
import media from '@/style/helpers/media'
import { parseApolloErrorPretty } from '@/utils/error'

const ForgotPasswordLink = styled(Text.sm)`
  display: inline;
  border-bottom: 0.7px solid black;
  padding-bottom: 1px;
  &:hover {
    cursor: pointer;
  }
  ::after {
    content: ' ';
    display: block;
  }
`

const LoginFormWrapper = styled.div`
  padding: 6rem 0 1rem 0;
  text-align: center;

  ${media.sm} {
    padding: 1rem 0;
  }

  ${media.md} {
    margin-top: 100px;
  }
`
const LinkWrapper = styled.div`
  margin-top: 16px;
`

const loginSchema = Yup.object().shape({
  email: Yup.string().email().min(2).required(),
  password: Yup.string().min(2).required(),
})

interface LoginProps {
  afterLoginAction?: (customerId: any) => void
  register?: JSX.Element
}

const Login: NextPage<LoginProps> = ({ afterLoginAction, register }) => {
  const { t } = useTranslation()

  const modalDialog = useContext(ModalDialogContext)
  const { error, loginCustomer } = useLoginCustomer({
    onCompleted: ({ loginCustomer }) => {
      if (loginCustomer) {
        tracking.trackEvent('login')
        afterLoginAction?.(loginCustomer._id)
        modalDialog.close()
      }
    },
  })

  const gqlErrorPretty = error ? parseApolloErrorPretty(error, t) : undefined

  return (
    <AuthDialog
      navBar={{
        title: t('common:auth.login'),
        link: {
          description: t('common:auth.no_account_yet'),
          text: t('common:auth.register'),
          onClick: () =>
            modalDialog.open(
              register ?? (
                <Register
                  afterRegisterAction={afterLoginAction}
                  headline={t('common:auth.register_headline')}
                  description={t('common:auth.register_description')}
                />
              ),
              {
                variant: 'full',
              },
            ),
        },
      }}
      fixedMobileFooter
      content={
        <LoginFormWrapper>
          <SectionInner textAlign="center" style={{ marginBottom: 15 }}>
            <Text.md weight="semibold">{t('common:auth.your_account')}</Text.md>
          </SectionInner>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={loginSchema}
            onSubmit={(values) =>
              loginCustomer({
                variables: {
                  username: values.email,
                  password: values.password,
                },
              })
            }
          >
            {(props) => {
              return (
                <Form>
                  <SmartTextInput
                    formikProps={props}
                    type="email"
                    name="email"
                    placeholder="E-Mail"
                    marginBottom="30px"
                  />
                  <SmartTextInput
                    formikProps={props}
                    type="password"
                    name="password"
                    placeholder="Passwort"
                    marginBottom="30px"
                  />
                  {gqlErrorPretty && (
                    <ErrorBox message={gqlErrorPretty.message} />
                  )}
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={props.isSubmitting}
                    className="w-full mt-4 px-6 py-2 flex justify-center items-center h-12 disabled:opacity-20"
                  >
                    {!props.isSubmitting ? (
                      t('common:auth.login')
                    ) : (
                      <div className="animate-spin [animation-timing-function:ease]">
                        <CashyLogo />
                      </div>
                    )}
                  </Button>
                </Form>
              )
            }}
          </Formik>
          <LinkWrapper>
            <ForgotPasswordLink
              as="span"
              onClick={() =>
                modalDialog.open(<ForgotPassword fixedMobileFooter />, {
                  variant: 'full',
                })
              }
            >
              {t('common:auth.have_password_forgotten')}
            </ForgotPasswordLink>
          </LinkWrapper>
        </LoginFormWrapper>
      }
    />
  )
}

export default Login
