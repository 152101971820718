import { ISbStoryData } from '@storyblok/react'
import { NextSeo } from 'next-seo'
import { LocalBusinessJsonLd } from 'next-seo'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import useRegionCode from '@/helpers/useRegionCode'
import { ERegionCode } from '@/types/gql/graphql'
import { PageStoryblok } from '@/types/storyblok-component-types'
import { SeoLocalBusinessStoryblok } from '@/types/storyblok-component-types'
import deepSearchStoryblok from '@/utils/deepSearchStoryblok'
import {
  defaultSeoTitle,
  getLanguageAlternates,
  regions,
} from '~/next-seo.config'

interface StoryblokSeoProps {
  story: ISbStoryData<{
    metatags?: PageStoryblok['metatags']
    config?: PageStoryblok['config']
    canonical_path?: string
    noindex?: boolean
  }>
}

export default function StoryblokSeo({ story }: StoryblokSeoProps) {
  const { canonical_path, noindex, metatags } = story.content

  const {
    twitter_image,
    twitter_description,
    twitter_title,
    title = defaultSeoTitle,
    description,
    og_image,
    og_description,
    og_title,
  } = metatags || {}

  const metaImages = useMemo(() => {
    return [
      twitter_image ? { url: twitter_image } : undefined,
      og_image ? { url: og_image } : undefined,
    ].filter(Boolean) as { url: string }[]
  }, [twitter_image, og_image])

  const titleWithSuffix = useMemo(() => {
    const tmp = title ?? ''
    if (tmp.toLowerCase().includes(defaultSuffix.toLowerCase())) {
      return tmp
    }

    return `${tmp} | ${defaultSuffix}`
  }, [title])

  const regionCode = useRegionCode()
  const { asPath: path, locale, defaultLocale } = useRouter()

  const canonical = useMemo(() => {
    const localePrefix = locale === defaultLocale ? '' : locale
    const url = new URL(
      localePrefix + (canonical_path || path),
      regions[regionCode].baseUrl,
    )
    url.hash = ''

    if (deepSearchStoryblok(story as any, 'product-selector')) {
      const page = url.searchParams.get('page')
      if (page && page !== '1') {
        url.search = new URLSearchParams({ page }).toString()
      } else {
        url.search = ''
      }
    } else if (deepSearchStoryblok(story as any, 'new-product-selector')) {
      const params = new URLSearchParams()

      const otherProducts = url.searchParams.get('other_products') === '1'
      if (otherProducts) {
        params.set('other_products', '1')
      }

      const page = Number.parseInt(url.searchParams.get('page') ?? '')
      if (!Number.isNaN(page) && page > 1) {
        params.set('page', page.toFixed(0))
      }

      url.search = params.toString()
    } else {
      url.search = ''
    }

    return url.href
  }, [canonical_path, story, path, regionCode, locale, defaultLocale])

  const languageAlternates = useMemo((): {
    href: string
    hrefLang: string
  }[] => {
    const availableRegions = [
      regionCode,
      ...story.alternates
        .filter((a) => a.published)
        .map((a) => {
          const [rootFolderName] = a.full_slug.split('/') // currently either 'at' or 'de'

          switch (rootFolderName) {
            case 'at':
              return ERegionCode.At

            case 'de':
              return ERegionCode.De

            default:
              console.warn('Failed to match root folder to region code.', a)
          }
        })
        .filter((a): a is Exclude<typeof a, undefined> => a !== undefined),
    ]

    return getLanguageAlternates(availableRegions, path)
  }, [regionCode, path, story.alternates])

  return (
    <>
      <NextSeo
        noindex={noindex}
        title={titleWithSuffix}
        description={description}
        titleTemplate="%s"
        canonical={canonical}
        languageAlternates={languageAlternates}
        openGraph={{
          title: twitter_title || og_title,
          description: twitter_description || og_description,
          images: metaImages,
        }}
      />
      {story.content.config?.map((blok) => {
        if (isLocalBusinessSeoConfig(blok)) {
          return <LocalBusinessSeo config={blok} />
        }
        throw new Error('Unknown config type')
      })}
    </>
  )
}

type LocalBusinessSeoProps = {
  config: SeoLocalBusinessStoryblok
}

function LocalBusinessSeo({ config }: LocalBusinessSeoProps) {
  const url = getStoryBlokLink(config.url)

  const images = useMemo(() => {
    if (!config.image) {
      return undefined
    }
    return ['750x750', '800x600', '1024x576'].map(
      (size) => `${config.image!.filename}/m/${size}/smart`,
    )
  }, [config.image])

  return (
    <LocalBusinessJsonLd
      type="FinancialService"
      id={url}
      url={url}
      images={images}
      name={config.name}
      description={config.description!} // Types are wrong, according to the docs a description is optional.
      address={{
        streetAddress: config.street,
        addressLocality: config.city,
        postalCode: config.zip,
        addressCountry: config.country,
      }}
      geo={{
        latitude: config.latitude,
        longitude: config.longitude,
      }}
      telephone={config.phone}
    />
  )
}

function isLocalBusinessSeoConfig(
  config: any,
): config is SeoLocalBusinessStoryblok {
  return config.component === 'SeoLocalBusiness'
}

const defaultSuffix = 'CASHY'
