import CartIcon from '../assets/icon_cart.svg'
import Link from 'next/link'
import { cn } from '@/utils/cn'

interface CartButtonProps {
  itemsCount: number
}

export default function CartButton({ itemsCount }: CartButtonProps) {
  return (
    <Link
      className="h-full w-10 flex flex-col justify-center"
      href="/checkout/items"
    >
      <div
        id="header_cart"
        className="size-10 relative flex flex-col items-center"
      >
        <CartIcon className="h-full" />
        <span
          className={cn(
            'text-accent-500 font-bold text-xs absolute top-[1px]',
            itemsCount >= 10 ? 'right-[0.74rem]' : 'right-4',
          )}
        >
          {itemsCount}
        </span>
      </div>
    </Link>
  )
}
