import { motion } from 'framer-motion'
import React, { ComponentProps, FunctionComponent } from 'react'
import styled from 'styled-components'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { UnstyledButton } from '@/style/components/Button'
import { media } from '@/style/helpers'
import { RichtextStoryblok } from '@/types/storyblok-component-types'
import { AnimatePresence } from '@/utils/animatePresence'

interface ItemProps {
  title: RichtextStoryblok | undefined
  className?: string
  isOpen?: boolean
  toggle?: Function
  children: React.ReactNode
  id: string
}

const AccordeonItem: FunctionComponent<ItemProps> = ({
  children,
  title,
  className,
  isOpen,
  toggle,
  id,
}) => {
  return (
    <AccItemWrapper className={className}>
      <AccItemHeader
        onClick={() => {
          toggle && toggle()
        }}
        className="before:text-fill-accent-primary"
        isOpen={isOpen}
        aria-expanded={isOpen}
        aria-controls={`faq-item-content-${id}`}
      >
        <TitleWrapper>
          <StoryblokRichText document={title} />
        </TitleWrapper>
      </AccItemHeader>
      <AnimatePresence initial={false}>
        <motion.div
          id={`faq-item-content-${id}`}
          aria-describedby={`faq-item-body-${id}`}
          key="content"
          initial="collapsed"
          animate={isOpen ? 'open' : 'collapsed'}
          variants={{
            open: { height: 'auto' },
            collapsed: { height: 0 },
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          style={{ overflow: 'hidden' }}
        >
          <AccItemBody id={`faq-item-body-${id}`}>{children}</AccItemBody>
        </motion.div>
      </AnimatePresence>
    </AccItemWrapper>
  )
}

export default AccordeonItem

const AccItemWrapper = styled.article`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

const AccItemHeader = motion<
  ComponentProps<typeof UnstyledButton> & { isOpen?: boolean }
>(styled(UnstyledButton)<{
  isOpen?: boolean
}>`
  padding: 1.25rem 1rem 1.25rem 3rem;
  position: relative;
  width: 100%;
  text-align: left;

  &::before {
    position: absolute;
    left: 1rem;
    content: '${({ isOpen }) => (isOpen ? '-' : '+')}';
    font-size: 1.5em;
    transform: translateY(-0.25em);
  }
`)

const AccItemBody = styled.div`
  padding: 0 1rem 1.25rem 3rem;

  font-size: 0.8rem;
  line-height: 1.2rem;

  ul {
    display: block;
    list-style-type: disc;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0.5rem;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0.5rem;
  }

  ${media.xs} {
    font-size: 0.9rem;
  }

  ${media.sm} {
    font-size: 1rem;
  }
`

const TitleWrapper = styled.h3`
  padding-top: 3px;
  font-weight: 600;
`
